<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/jiaoyi_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 知识产权转化 -->
		<div class="block58"></div>
		<div class="huaxue_zscqzh_box">
			<!-- <div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">知识产权转化</div>
				<div class="type_desc fontsize14">INTELLECTUAL PROPERTY TRANSACTION</div>
			</div>
			<div class="block10"></div> -->
			<div class="huaxue_zhbox w1260">
				<div class="zhitem" v-for="(item,index) in typeList" :key="index" @click="routerPath(item)">
					<img class="img" :src="item.imgUrl"/>
					<div class="text1 fontsize20">{{item.title}}</div>
				</div>
			</div>
		</div>
		<!-- 专利展示 -->
		<div class="block60"></div>
		<div class="huaxue_zscqzs_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">知识产权交易</div>
				<div class="type_desc fontsize14">PATENT DISPLAY</div>
			</div>
			<div class="huaxue_home_type w1239">
				<div class="title fontsize38"></div>
				<div class="typelist">
					<div class="typeobj fontsize22" @click="$util.routerPath('/deal')">查看更多</div>
				</div>
			</div>
			<div class="huaxue_zscqzs relative w1240" v-if="swiperOptions.isshow">
				<div class="btn_prev" @click="slidePrev"><img src="../assets/static/icon_left.png"></div>
				<div class="btn_next" @click="slideNext"><img src="../assets/static/icon_right.png"></div>
				<div>
					<swiper ref="mySwiper" :options="swiperOptions">
					    <swiper-slide v-for="(item,index) in dealList" :key="index">
					    	<div class="tuijian_item">
					    		<div class="img"><img :src="item.imgUrl || require('../assets/static/noimg.png')"></div>
					    		<div class="block10"></div>
								<div class="title fontsize14 clamp">{{item.title}}</div>
					    		<div class="block10"></div>
					    		<div class="desc fontsize12">申请号：{{item.applyNo}}</div>
					    		<div class="desc fontsize12">申请人：{{item.applicant}}</div>
					    		<div class="desc fontsize12">申请日：{{item.applyDate}}</div>
					    		<div class="more fontsize16" @click="$util.routerPath('/dealDetail',{uid:item.id})">查看更多</div>
					    	</div>
					    </swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//页面引入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			typeList:[
				{
					title:"知产交易",
					titleEN:"",
					imgUrl:require("../assets/static/Frame001.png"),
					content:"",
					infoType:"deal",
				},
				{
					title:"知产质押融资",
					titleEN:"",
					imgUrl:require("../assets/static/Frame002.png"),
					content:"",
					infoType:"ziyarongzi",
				},
				{
					title:"知产保险",
					titleEN:"",
					imgUrl:require("../assets/static/Frame003.png"),
					content:"",
					infoType:"baoxian",
				},
				{
					title:"知产投融资",
					titleEN:"",
					imgUrl:require("../assets/static/Frame004.png"),
					content:"",
					infoType:"tourongzi",
				},
				{
					title:"科创板上市辅导",
					titleEN:"",
					imgUrl:require("../assets/static/Frame005.png"),
					content:"",
					infoType:"shangshifudao",
				}
			],
			dealList:[],
			complete:false,//加载全部
			formobj:{
				state:0,//上架
				currentPage:1,
				pageSize:15,
				title:"",
			},
			swiperOptions:{
				isshow:false,
				slidesPerView: 5,
			},
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(5).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.getdataList()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转
		routerPath(item){
			const path = item.infoType
			const name = item.title
			if(path){
				if(path=="deal"){
					this.$util.routerPath('/deal')
				}else if(path=="ziyarongzi" || path=="baoxian" || path=="tourongzi" || path=="shangshifudao"){
					this.$util.routerPath('/finance',{type:path,typeName:name})
				}else{}
				
			}
		},
		//上一页
		slidePrev() {
		    this.$refs.mySwiper.swiper.slidePrev();
		},
		//下一页
		slideNext() {
		    this.$refs.mySwiper.swiper.slideNext()
		},
		//加载产权交易列表
		getdataList() {
			var _this = this;
			var params = this.formobj
			this.$http.post('frontEnd/transaction/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.dealList = records
					_this.swiperOptions.isshow = true
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
